/*
 * @Author: shao
 * @Date: 2023-08-23 16:24:04
 * @LastEditors: [you name]
 * @LastEditTime: 2023-09-01 15:13:35
 * @Description: 
 */
import React, { useState, useEffect, useCallback } from 'react';
import "./index.scss";
import { Form, Input, Button, Toast, SafeArea, NavBar, Picker, Popup,Modal } from 'antd-mobile';
import { useHistory, Link } from 'react-router-dom';
import Logo from '@/images/login-mobile-logo.png'
import OneStepIcon from '@/images/pay-step-one-icon.png'
import TwoStepIcon from '@/images/pay-step-two-icon.png'
import ThreeStepIcon from '@/images/pay-step-three-icon.png'
import OneStepFlagIcon from '@/images/pay-step-sign-flag.png'
import PayFail from '@/images/pay-fail.png'

import { getSignUp } from '../../api/index/index';


const Index = () => {
   const history = useHistory();
   const [userName, setUserName] = useState()
   const [visible, setVisible] = useState(false)

   const [visible6, setVisible6] = useState(false)
   const [payVisible,setPayVisible]=useState(false)
   const [entity, setEntity] = useState({})

   const back = () => {
       history.push('/fill')
     // setPayVisible(true)
   }

   useEffect(() => {
      getSignUp().then(res => {
         if (res) {
            setEntity(res)
         }
      })
   }, [])

  const onBridgeReady=()=>{
   let appId = ""
   let timeStamp = ""
   let nonceStr = ""
   let packageStr = ""
   let signType = ""
   let paySign = ""
   window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
      'appId': appId,
      'timeStamp':timeStamp,
      'nonceStr': nonceStr,
      'package': packageStr,
      'signType': signType,
      'paySign': paySign
   }, function (response) {
      if (response.err_msg == "get_brand_wcpay_request:ok") {
         Toast.show('支付成功')
         history.push("/success")
      } else {
         // 有些用户调起了支付，但是未付款取消的处理方式，你可以给他简单简单提示
         Toast.show('支付异常取消')

      }
   });
  }
   const nextStep = () => {
      // history.push("/success")

      if (isWeChat) {
        
         if (typeof window.WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
        } else {
            onBridgeReady();
        }
        
        
      } else {
         window.location.href = 'https://wx.tenpay.com/cgi-bin/mmpayweb-bin/checkmweb?prepay_id=wx2016121516420242444321ca0631331346&package=1405458241'
      }
   }
   const isWeChat = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
         return true;
      } else {
         return false;
      }
   }



   const onUserNameChange = useCallback((value) => {
      //  let target = e.target || e.currentTarget;
      //  console.log(target.value)
      setUserName(value)
   })
   return <div className="pay-container" >


      <NavBar onBack={back}></NavBar>
      <img src={Logo} className="logo"></img>
      <div className="row">
         <img src={OneStepIcon} ></img>
         <img src={TwoStepIcon}></img>
         <img src={ThreeStepIcon} ></img>
      </div>

      <div className="form-box">

         <div className="form-body">
            <div className='row-hr'>
               <img src={OneStepFlagIcon}></img>
               <div className="tip">请认真核对以上信息</div>
            </div>

            <div className="item">

               <div className="row-one">
                  <div className="label">姓名：</div>
                  <div>{entity.realname}</div>
               </div>
               <div className="row-one">
                  <div className="label">报考类型：</div>
                  <div>{entity.type}</div>
               </div>
               <div className="row-one">
                  <div className="label">报考专业：</div>
                  <div>{entity.major}</div>
               </div>
               <div className="row-one">
                  <div className="label">报考费用：</div>
                  <div>{entity.amount ? "¥"+entity.amount / 100.0.toFixed(2) : ''}</div>
               </div>

               <div className="row-one">
                  <div className="label">订单编号：</div>
                  <div>张发财</div>
               </div>
               <div className="row-one">
                  <div className="label">下单时间：</div>
                  <div>张发财</div>
               </div>

               <button className="btn" onClick={nextStep}>支付</button>

            </div>

         </div>

      </div>

      <Modal
        visible={payVisible}
        content={
           <div className="dialog">
              <img src={PayFail} className="dialog-img"></img>
              <div>抱歉，订单支付失败</div>
              <div className="dialog-row">
              <div className="dialog-btn" onClick={()=>{
                 setPayVisible(false)
              }}>取消订单</div>
              <div className="dialog-btn on" onClick={()=>{
              setPayVisible(false)
              }}>重新支付 </div>
              </div>
           </div>
        }
        closeOnAction
        onClose={() => {
          setPayVisible(false)
        }}
        
      />
      
   </div>
}

export default Index