/*
 * @Author: shao
 * @Date: 2023-08-23 16:24:04
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-31 14:28:33
 * @Description: 
 */
import React, { useState, useEffect, useCallback } from 'react';
import "./index.scss";
import { Form, Input, Button, Toast, SafeArea, NavBar } from 'antd-mobile';
import { useHistory, Link } from 'react-router-dom';
import CodeIcon from '@/images/download-logo.png'
const Index = () => {
   const history=useHistory()

   const list=[
      '1.自愿报名，并愿意遵守相关的管理；',
      '2.根据班主任的要求如实提交个人信息，以便完成系统注册，帮助你顺利完成建档；',
      '3.学习期间必须严格按照教学要求、按时参加考试，经考试合格后予以毕业，并配合办理毕业手续；',
      '4.需一次性缴纳费用，包括但不限于报名注册、学费服务、报考服务、学习过程辅导、考试辅导以及毕业手续办理等，考试及信息采集照相费按照实际价格另行收取；',
      '5.学员原则上不得中途退学，如必须退费，将根据退学时间扣除已缴费学年的费用后，退还其余费用；',
      '6.如实提交注册建档所需电子材料，不得弄虚作假，如因材料造假、提交不及时等愿意造成录取失败将扣除费用的20%；',
      '7.建议您非必要不更换手机号码，如确因需要变更手机号码后，及时与班主任报备；',
      '8.注册建档学习平台网址：http://student.qwbx.ouchn.cn/',
      '9.学籍查询平台：www.chsi.com.cn（学信网）；',
      "10.请关注“工匠码”App及“一码直聘”微信公众号，以便了解考试事宜。"


   ]
  

   const back = () => {
     history.goBack()
   }

   const arrCom=()=>{
     
   }

 
   return <div className="web-box" >


      <NavBar onBack={back} >学员须知</NavBar>
     
      {list.map((item,index)=>{
         return <div className="item">{item}</div>
      })}

     

      <div className="right">
        <div>北京技能码科技有限公司</div>
        <div>2023年9月</div>
        
                                                
      </div>
      <div className="item">（苹果系统可在苹果商店搜索“工匠码”直接下载，安卓系统请扫码下载APP）</div>
      <div className="center">
         <img src={CodeIcon}></img>
      </div>


   </div>
}

export default Index