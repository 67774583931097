/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-06-07 09:40:49
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-22 14:02:53
 */
import "polyfill"
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import RouterView from './router';
import store, { persistor } from './store';
import  {PersistGate}  from 'redux-persist/integration/react';

function App() {
  useEffect(() => {
    // 禁止页面拖动
    document.ondragstart = function () {
      return false;
    };
  }, []);
 
  const El:any = PersistGate
  return (
    <Provider store={store}>
      <El persistor={persistor}>
        <RouterView />
      </El>
    </Provider>
  );
}

export default App;
