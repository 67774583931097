/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2022-08-11 12:54:02
 * @LastEditors: nliu
 * @LastEditTime: 2023-04-17 18:17:51
 */
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd-mobile/es/global'
import App from './App';
import './style/custom.scss';
import './style/common.scss';
import 'swiper/swiper-bundle.min.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as serviceWorker from './serviceWorker';
import "./style/theme.scss"
import "./utils/amfe-flexible"
import { SafeArea } from 'antd-mobile'
ReactDOM.render(<>
  <div style={{ background: '#ace0ff' }}>
    <SafeArea position='top' />
  </div>
  <App />
  <div style={{ background: '#ffcfac' }}>
    <SafeArea position='bottom' />
  </div>
</>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
