/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-08-11 12:54:02
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-01 13:55:05
 */
import { createStore } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducer';
const persistConfig = {
  storage,
  key: 'm:distribution:root',
  stateReconciler: autoMergeLevel2,
};

const myPersistReducer = persistReducer(persistConfig, rootReducer as any);

const store = createStore(myPersistReducer);

export const persistor = persistStore(store);

export default store;
