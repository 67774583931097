/*
 * @Descripttion:
 * @version:
 * @Author: nliu
 * @Date: 2022-06-07 09:40:49
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-31 14:24:48
 */
import { Toast } from 'antd-mobile';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import * as history from 'history';
import store from '../store';
import getHearders from '../utils';

let timer: any = null;
function showError(msg: string): void {
  timer && clearTimeout(timer);
  timer = setTimeout(() => {
    Toast.show(msg);
  }, 1000);
}

export function request(config: AxiosRequestConfig): AxiosRequestConfig {
  const s: any = store.getState()
 

  let token=localStorage.getItem("token")

  if((config?.url || "").indexOf('?') >= 0) {
    config.url = config.url
  } else {
    config.url = config.url
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...getHearders(config.headers?.token || token),
    },
  };
}

export function response(response: AxiosResponse): AxiosResponse | any {
  const { data, status, statusText } = response;
  let msg = '网络错误~';
  console.log("拦截>>>>>",response)
  if (statusText) msg = statusText;
  if (status >= 200 && status <= 300) {
    if (data) {
      if (data.code === 100000) return data.data || data;
      if (data.code === 200001||data.code===200000) {
        msg = data.message
        Toast.show({
          content: msg, afterClose: () => {
            history.createHashHistory().push('/login');
            window.location.reload();
          },
          maskClickable:false,
          duration:1500
        })
        return Promise.reject(msg);

      } else if (data.code === 8 || data.code === 9) {

        return data;
      } else {
        msg = data.message || msg;
      }


    } else {
      msg = '服务器错误~';
    }
  }else{
    
  }

  showError(msg);
  return Promise.reject(msg);
}
