/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-08-11 12:54:02
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-16 18:01:28
 */
export const UPDATE_TOKEN: string = 'UPDATE_TOKEN';

export const CLEAR_TOKEN: string = 'CLEAR_TOKEN';

export const UPDATE_USER: string = 'UPDATE_USER';

export const CLEAR_USER: string = 'CLEAR_USER';

export const UPDATE_ORGANIZATION: string = 'UPDATE_ORGANIZATION';

export const CLEAR_ORGANIZATION: string = 'CLEAR_ORGANIZATION';

export const UPDATE_ORDERDETAIL: string = 'UPDATE_ORDERDETAIL';

export const CLEAR_ORDERDETAIL: string = 'CLEAR_ORDERDETAIL';






