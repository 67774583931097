/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-08-11 12:54:02
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-16 18:02:57
 */
import * as types from './constant';

import { State, Action } from './interface';

const initialState: State = {
  token: null,
  user: {},
  organization:{},
  order:{}
};

export default function (state = initialState, action: Action): State {
  const { payload: { 
    token = null, 
    user = {},
    organization={},
    order={}
  } = {}, type } = action;

  switch (type) {
    case types.UPDATE_TOKEN:
      return {
        ...state,
        token,
      };
    case types.CLEAR_TOKEN:
      return {
        ...initialState,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        user,
      };
      case types.CLEAR_ORGANIZATION:
        return {
          ...initialState,
        };
      case types.UPDATE_ORGANIZATION:
        return {
          ...state,
          organization,
        };
        case types.CLEAR_ORDERDETAIL:
          return {
            ...initialState,
          };
        case types.UPDATE_ORDERDETAIL:
          return {
            ...state,
            order,
          };
    default:
      return state;
  }
}


