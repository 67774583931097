/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2022-08-11 12:54:02
 * @LastEditors: nliu
 * @LastEditTime: 2022-09-16 18:01:38
 */
import * as types from './constant';
import { Action } from './interface';

export function updateToken(token: string): Action {
  return {
    type: types.UPDATE_TOKEN,
    payload: {
      token,
    },
  };
}

export function clearToken(): Action {
  return {
    type: types.CLEAR_TOKEN,
  };
}

export function updateUser(user = {}): Action {
  return {
    type: types.UPDATE_USER,
    payload: {
      user,
    },
  };
}

export function clearUser(): Action {
  return {
    type: types.CLEAR_USER,
  };
}


export function updateOrganization(org = {}): Action {
  return {
    type: types.UPDATE_ORGANIZATION,
    payload: {
      organization:org,
    },
  };
}

export function clearOrganization(): Action {
  return {
    type: types.CLEAR_ORGANIZATION,
  };
}

export function updateOrderDetail(order = {}): Action {
  return {
    type: types.UPDATE_ORDERDETAIL,
    payload: {
      order,
    },
  };
}

export function clearOrderDetail(): Action {
  return {
    type: types.CLEAR_ORDERDETAIL,
  };
}

