/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-07-21 14:29:51
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-23 16:26:38
 */
import React from 'react';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { createHashHistory } from 'history';
import routes from './routes';
import { connect } from 'react-redux';
const history = createHashHistory();
function RouterView({ token = null } = {}) {
  
  return (
    <Router history={history} >
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={(props) =>
              // !token && props.location.pathname !== '/login' && props.location.pathname !== '/ssologin' && props.location.pathname !== '/loginAndroid' && props.location.pathname !== '/loginIos' && props.location.pathname !== '/agreement' ? (
              //   <Redirect to="/login" />
              // ) : (
                <route.component {...props} routes={route.routes} />
              // )
            }
          />
        ))}
        <Redirect exact from="/" to="/login" />
      </Switch>
    </Router>
  );
}

export default connect((state) => ({ ...state }))(RouterView);
