/*
 * @Author: shao
 * @Date: 2023-08-23 16:24:04
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-25 15:35:43
 * @Description: 
 */
import React, { useState, useEffect, useCallback } from 'react';
import "./index.scss";
import { Form, Input, Button, Toast, SafeArea, NavBar } from 'antd-mobile';
import { useHistory, Link } from 'react-router-dom';
import Logo from '@/images/login-mobile-logo.png'
import OneStepIcon from '@/images/pay-step-one-icon.png'
import TwoStepIcon from '@/images/pay-step-two-icon.png'
import ThreeStepIcon from '@/images/pay-step-three-icon.png'
import OneStepFlagIcon from '@/images/pay-step-flag.png'
import CodeIcon from '@/images/pay-success-code.png'
const Index = () => {
   const history=useHistory()
   const [userName, setUserName] = useState()
   const [visible, setVisible] = useState(false)

   const [visible6, setVisible6] = useState(false)
   const [value, setValue] = useState()

   const back = () => {
      history.replace("/login")
   }

   const onUserNameChange = useCallback((value) => {
      //  let target = e.target || e.currentTarget;
      //  console.log(target.value)
      setUserName(value)
   })
   return <div className="index-container" >


      <NavBar onBack={back}></NavBar>
      <img src={Logo} className="logo"></img>
      <div className="row">
         <img src={OneStepIcon} ></img>
         <img src={TwoStepIcon}></img>
         <img src={ThreeStepIcon} ></img>
      </div>

      <div className="form-box">

         <div className="form-body">
            <div className='row-one'>
               <img src={OneStepFlagIcon}></img>
               <div className="tip">信息已经提交成功</div>
            </div>

            <div className="item">
               <div className="title">已提交报名</div>
               <div className="desc">请扫描下方二维码，添加<br></br>班主任完成后续学习</div>
               <img src={CodeIcon} ></img>
            </div>

         </div>

      </div>



   </div>
}

export default Index