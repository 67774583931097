import md5 from 'md5';
export const imgwebUrl = (url: string, proName:string='m'): string => `/public_static/distribution/${proName}/${url}?123`;

export function colorHex(color: string): string {
  const reg = /^(rgb|RGB)/;

  if (reg.test(color)) {
    let strHex = '#';
    // 把RGB的3个数值变成数组
    let colorArr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',');
    // 转成16进制
    for (var i = 0; i < colorArr.length; i++) {
      let hex = Number(colorArr[i]).toString(16);
      if (hex === '0') {
        hex += hex;
      }
      strHex += hex;
    }
    return strHex;
  }

  return color;
}

export function colorRgb(c: string): string {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  let color = c.toLowerCase();

  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      let colorNew = '#';
      for (var i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    let colorChange = [];
    for (let i = 1; i < 7; i += 2) {
      colorChange.push(parseInt('0x' + color.slice(i, i + 2)));
    }
    return 'RGB(' + colorChange.join(',') + ')';
  }

  return color;
}

export default function getHearders(token: string | null) {
  var random = '' + Math.floor(Math.random() * 10000) + Date.now();
  let data = {
    tk: '01007602e005',
    zn: random,
    sd: getRandom(md5(random)),
  };

  if (token) (data as any).token = token;

  return data;
}
function getRandom(str: string): string {
  return base64(str);
}
function base64(input: string): string {
  let _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let output = '';
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;
  input = utf8Encode(input);
  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output = output + _keyStr.charAt(enc1) + _keyStr.charAt(enc2) + _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
  }
  return output;
}
function utf8Encode(string: string): string {
  string = string.replace(/\r\n/g, '\n');
  var utftext = '';
  for (var n = 0; n < string.length; n++) {
    var c = string.charCodeAt(n);
    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if (c > 127 && c < 2048) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }
  }
  return utftext;
}

export function showImg(id = '', initialId = '') {
  if (id) return `/api/media/api/v1/media/showImage/${id}`;

  if (initialId) return `/api/media/api/v1/media/showImage/${initialId}`;

  return '';
}

// export function addBackground(ele: any) {
//   if (!ele) return;
//   let containerList = ele.getElementsByClassName('echarts-for-react');
//   let container = containerList && containerList[0];
//   let targetList = container && container.getElementsByTagName('div');
//   let target = targetList && targetList[0];
//   let img = document.createElement('img');
//   img.src = imgUrl('num.png');
//   target && target.appendChild(img);
// }

export function getIp() {
  return window.location.origin;
}
export function removeImgHeight (html: any) {
  var newContent= html.replace(/<img[^>]*>/gi,function(match: any){
  var match = match.replace(/height="\d+\"/gi, '');
    return match;
  });
  return newContent;
}
