/*
 * @Author: shao
 * @Date: 2023-08-23 16:14:09
 * @LastEditors: [you name]
 * @LastEditTime: 2023-08-26 13:33:59
 * @Description: 
 */

import {lazy} from 'react'
import Login from '../page/Login/Login'; // 登录
import Index  from '../page/Index/index';
import Pay  from '../page/pay/index';
import SuccessPage  from '../page/success/index';
import FillPage  from '../page/infoFill/index';
import AgreementPage  from '../page/agreement/index';
const routes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path:'/index',
    component:Index
  },{
    path:'/pay',
    component:Pay
  },{
    path:'/success',
    component:SuccessPage
  },
  ,{
    path:'/fill',
    component:FillPage
  },
  {
   
      path:'/agreement',
      component:AgreementPage
    
  }

];

export default routes;


