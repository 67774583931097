
import React, { useState, useEffect, useCallback } from "react";
import md5 from 'md5';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Form, Input, Button, Toast, SafeArea, NavBar } from 'antd-mobile';

import axios from '../../axios';
import { updateToken, updateUser, updateOrganization } from '../../store/action';
import { login, sendSms } from '../../api/index/index';


import "./css/index.scss";

import Logo from '@/images/login-mobile-logo.png'
import CheckGrey from '@/images/login-mobile-check-grey.png'
import CheckSel from '@/images/login-mobile-check-sel.png'
import PwdCloseIcon from '@/images/icon_pwd_close.png'
import PwdOpenIcon from '@/images/icon_pwd_open.png'
import { convertToObject } from "typescript";

const Login = (props) => {
  const history = useHistory();
  const [canClick, setCanClick] = useState(false)
  const [userName, setUserName] = useState()
  const [pwd, setPwd] = useState()
  const [checked, setChecked] = useState(false)

  const [codeTip, setCodeTip] = useState()
  const [disabled, setEnabled] = useState(false)
  let inputRef;

  //================以此为界 其他：包含 接口请求 逻辑处理================================
  const _login = () => {
    let param = {
      mobile: userName,
      code: pwd
    }
    login(param).then(res => {
      console.log(">>>>>", res)
      localStorage.setItem("uid", res.uid)
      localStorage.setItem("token", res.token)
      history.push("/index")
    })
  }

  const resetUserInfo = useCallback(
    (res, org) => {
      const { user } = res;
      console.log(user.token);

      Toast.show('登录成功');
      props.updateToken(user.token);
      props.updateUser(user);
      props.updateOrganization(org)

      history.push('/index');
    },
    [props, history]
  );
  //================以此为界 事件方法回调================================

  const onFinish = useCallback(
    async () => {
      try {

        _login({ userName, password: pwd })
      } catch (e) {

      }
    },
    [userName, pwd, _login, resetUserInfo]
  );


  const onUserNameChange = useCallback((value) => {
    //  let target = e.target || e.currentTarget;
    //  console.log(target.value)
    setUserName(value)
  })

  const onPasswordChange = useCallback((value) => {
    // let target = e.target || e.currentTarget;
    // console.log(target.value)
    setPwd(value)
  })

 

  const clickRule = () => {
    history.push("/agreement")
  }
  const clickChecked = (e) => {
    setChecked(!checked)
  }

  const sendCode = () => {
    if(!userName){
      Toast.show("请填写您的手机号")
      return
    }
    let phone = userName
    let data = {
      mobile: phone
    }
    sendSms(data).then(res => {
      if (disabled) return;
      setEnabled(true)
      let n = 60;
      setCodeTip('剩余' + n + "s")
      const run = setInterval(() => {
        n = n - 1;
        if (n < 0) {
          clearInterval(run);
          setEnabled(false)
        }
        setCodeTip('剩余' + n + "s")

      }, 1000);
    })


  }



  useEffect(() => {

    window.onload = () => {
     
      let u = window.navigator.userAgent;
      if (u.indexOf('Android') >= 0 || u.indexOf('Liunx') >= 0) {
        console.log(inputRef)
        let el = inputRef.nativeElement;
        var originHeight = document.documentElement.clientHeight || document.body.clientHeight;
        var bodyE = document.getElementsByClassName('login-home')[0];
        window.addEventListener('resize', () => {
         
          var resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;

         
          if (originHeight < resizeHeight) {
          
            // Android 键盘收起后操作
            bodyE.style.height = resizeHeight + 'px';
          } else {
          
            // Android 键盘弹起后操作
            bodyE.style.height = originHeight + 'px';
          }
          originHeight = resizeHeight;

        })
      }
    }
    let token = localStorage.getItem("token")
    if (token){
      history.push("/index")
    }
   
  }, [])

  //================以此为界 类似类组件的周期函数================================

  //================以此为界 类似类组件的 render 函数================

 

  return (
    <div className="login-home" style={{
      height: document.documentElement.clientHeight + 'px',
      overflow: "hidden"
    }}>
      <img src={Logo} className="logo"></img>
      <div className="form-box">

        <div className="form-body">
          <div className="label">手机号：</div>
          <div className="form-item">
            <Input value={userName} name="userName" placeholder="请填写您的手机号" maxLength={11} autoComplete={false} onChange={onUserNameChange} />
          </div>
          <div className="label">验证码：</div>
          <div className="form-item code">
            <Input ref={el => inputRef = el} maxLength={6} value={pwd} name="password" type={"text"} placeholder="请填写验证码" autoComplete={false} onChange={onPasswordChange} />
            <button className="btn" onClick={sendCode} disabled={disabled}>{disabled ? codeTip : "获取验证码"}</button>
          </div>

          <div className="row">
            <img src={checked ? CheckSel : CheckGrey} className="img" onClick={clickChecked} />
            <div>阅读并同意<span onClick={clickRule}>《学员协议》</span></div>
          </div>

          <div>
            <button className={userName&&pwd&&checked?'login_btn on':'login_btn'}
              //disabled={!(userName && pwd)}  
              onClick={onFinish} >登录</button>

          </div>
        </div>
      </div>
      <div className="common_footer">
        <SafeArea position='bottom'></SafeArea>
      </div>
    </div>
  );
};
const mapAction = (dispatch) => ({
  updateToken(token) {
    dispatch(updateToken(token));
  },
  updateUser(user) {
    dispatch(updateUser(user));
  },
  updateOrganization(org) {
    dispatch(updateOrganization(org))
  }

});

export default connect(null, mapAction)(Login);
